<p>header works!</p>
<header id="header" class="header fixed-top d-flex align-items-center">
  <div class="d-flex align-items-center justify-content-between">
    <a class="logo d-flex align-items-center" (click)="onClickLogo()">
      <img id="header-logo" src="assets/images/secure/header-logo.png" alt="" />
    </a>
    <i id="toggle-sidebar" class="bi bi-list toggle-sidebar-btn" (click)="toggleSidebar()"></i>
  </div>

  <!-- Start Search Bar -->
  <!-- <div class="search-bar">
    <form class="search-form d-flex align-items-center" method="POST" action="#">
      <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
      <button type="submit" title="Search"><i class="bi bi-search"></i></button>
    </form>
  </div> -->
  <!-- End Search Bar -->

  <nav class="header-nav ms-auto">
    <button id="start-earning-btn" type="button" class="btn btn-secondary me-5" style="float: inline-start" (click)="switchView()">
      <i *ngIf="buttonText === 'Start Earning Today!'" class="bi bi-currency-dollar me-1"></i>
      {{ buttonText }}
    </button>

    <ul class="d-flex align-items-center">
      <!-- <li class="nav-item d-block d-lg-none">
        <a class="nav-link nav-icon search-bar-toggle">
          <i class="bi bi-search"></i>
        </a>
      </li> -->

      <li class="nav-item dropdown pe-3">
        <a id="profile-dropdown" class="nav-link nav-profile d-flex align-items-center pe-0" (click)="toggleFlag()">
          <div id="profile-picture" class="rounded-circle profile-image" data-cy="profileImage">{{ getInitials() }}</div>
          <span id="profile-title" class="d-none d-md-block dropdown-toggle ps-2" data-cy="userName">{{ userProfileName }}</span>
        </a>

        <ul
          class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"
          [ngClass]="{ show: showDropdown }"
          style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(-16px, 37.6px, 0px)"
          data-cy="dropdown-menu"
        >
          <li class="dropdown-header">
            <h6 id="main-profile-title" data-cy="profileName">{{ userProfileName }}</h6>
            <span id="main-profile-sub-title" data-cy="email">
              <ng-container *ngIf="isEmailVerified; else notVerified" data-cy="emailVerified">
                <i class="bi bi-envelope-check"></i>
              </ng-container>
              <ng-template #notVerified>
                <i class="bi bi-envelope-dash text text-danger" data-cy="emailNotVerified"></i>
              </ng-template>
              {{ userEmail }}
            </span>
          </li>

          <li>
            <hr id="dropdown-divider" class="dropdown-divider" />
          </li>

          <li>
            <a id="my-profile-menu-item" class="dropdown-item d-flex align-items-center" (click)="redirectToProfile()" data-cy="myProfile">
              <i class="bi bi-person"></i>
              <span>My Profile</span>
            </a>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>

          <!-- <li>
            <a change-password-menu-item class="dropdown-item d-flex align-items-center">
              <i class="bi bi-lock"></i>
              <span data-bs-toggle="modal" data-bs-target="#verticalycentered">Change Password</span>
            </a>
          </li> -->

          <li>
            <hr class="dropdown-divider" />
          </li>

          <li>
            <a (click)="logout()" id="sign-out-menu-item" class="dropdown-item d-flex align-items-center">
              <i class="bi bi-box-arrow-right"></i>
              <span data-cy="signOut">Sign Out</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</header>
