<div class="after-login-body">
  <poocho-header></poocho-header>
  <div [ngClass]="{ 'toggle-sidebar': isSidebarOpen }">
    <main id="main" class="main">
      <div class="row">
        <div class="pagetitle">
          <div class="row">
            <h1 class="col-md-8 col-lg-8 col-form-label">{{ title$ | async }}</h1>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </main>
  </div>
  <poocho-sidebar></poocho-sidebar>
  <poocho-footer></poocho-footer>
</div>
