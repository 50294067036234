import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from "@angular/router";
import { EmitterService } from "@core/services/emitter.service";
import { TitleService } from "@core/utility-services/title.service";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs";
import { constants } from "./app.constant";
import { MixpanelService } from "@core/services/mixpanel.service";

@Component({
  selector: "poocho-root",
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  showLoader: boolean = false;

  constructor(
    private translateService: TranslateService,
    private titleService: TitleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private emitterService: EmitterService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.mixpanelService.track("landing_page_visit");
    this.router.events.pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showLoader = true;
      } else if (event instanceof NavigationEnd) {
        this.titleService.setTitle(this.activatedRoute.snapshot);
        this.showLoader = false;
      }
    });

    this.translateService.setDefaultLang("en");
    this.translateService.use("en");

    this.emitterService.get(constants.events.hidePageLoader).subscribe(() => {
      this.showLoader = false;
    });
  }
}
