import { Routes } from "@angular/router";

import { ErrorComponent } from "./error.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";

export const errorRoute: Routes = [
  {
    path: "error",
    component: ErrorComponent,
    title: "Error Page !",
  },
  {
    path: "accessdenied",
    component: AccessDeniedComponent,
    title: "Error Page !",
  },
  {
    path: "404",
    component: ErrorComponent,
    data: {
      errorMessage: "error.http.404",
    },
    title: "Error Page !",
  },
];
