import { Component } from "@angular/core";
import { ErrorComponent } from "../error.component";

@Component({
  selector: "poocho-access-denied",
  standalone: true,
  imports: [ErrorComponent],
  templateUrl: "./access-denied.component.html",
  styleUrl: "./access-denied.component.scss",
})
export class AccessDeniedComponent {}
